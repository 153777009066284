import React from "react";
import { Link } from "react-router-dom";
import Sala2DentroLonge from "../../../../images/materialpisopitch/propriedade_intelectual/material_separado_propriedade_intelectual-16.png";


export default function Transition2() {
    return (
        <div>
            <img src={Sala2DentroLonge} useMap="#estudio1far-map" className="img-100" />
            <map name="#estudio1far-map">
                <Link to="/phases/propriedade/estudio2/transition3">
                    <area
                        target=""
                        alt="estudio_2"
                        title=""
                        coords="1000,600, 1100,300"
                        shape="rect"
                    />
                    <div className="propriedade-estudio2-transition3 pointer"></div>
                </Link>
            </map>
        </div>
    );
}
