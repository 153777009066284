import React from 'react';
import SarkarLogo from "../images/SarkarLogo/SarkarLogo.svg";
import { Col, Row } from 'react-bootstrap';

export default function SideBar() {
    return (
        <Row className="py-5">
            <Col>
                <img src={SarkarLogo} alt="Sarkar Logo" style={{ width: 100 }} className='sarkar-logo' />
            </Col>
        </Row>
    );
}
