import React, { useContext, useState, useEffect } from "react";

import axios from "axios";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";

import { SERVER_URL } from "../constants";

export default function Dashboard() {
    const navigate = useNavigate();
    const { auth } = useContext(AuthContext); // Use o hook useContext para acessar o valor do contexto
    const token = localStorage.getItem('token');

    const logout = () => {
        if (auth) {
            axios.get(`${SERVER_URL}/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${token}` // Adicione o token de autenticação
                },
                withCredentials: true, // Send cookies with the request
            })
                .then((res) => {
                    console.log("Sucessful loged out.");
                    navigate('/login');
                })
                .catch((err) => {
                    console.log(err);
                    navigate('/login');
                });
        }
    };

    useEffect(() => {
        logout();
    }, [auth, token]);

    return (
        <></>
    );
}