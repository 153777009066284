export const initialPlanData = [
    {
        title: "Plano de Negócios - O que é?",
        text:
            "O plano de negócios é uma ferramenta fundamental para empreendedores que desejam transformar \
            suas ideias em empresas de sucesso. Trata-se de um documento que detalha todos os aspectos do \
            futuro empreendimento, desde a concepção da ideia até a execução e gestão do negócio. O objetivo \
            principal do plano de negócios é fornecer um guia sólido que ajude o empreendedor a tomar decisões \
            informadas e a atrair investidores, parceiros e clientes em potencial. \n\n \
            Em sua essência, o plano de negócios é um roteiro estratégico que aborda diversos tópicos cruciais. \
            Isso inclui a descrição da empresa, seu mercado- alvo, análise da concorrência, estratégias de marketing, \
            plano de operações, estrutura organizacional, projeções financeiras e requisitos de financiamento. \
            Cada seção desse documento desempenha um papel importante na definição do caminho a ser seguido pelo empreendedor.\n\n \
            Além disso, o plano de negócios é uma ferramenta de comunicação eficaz.Ele permite que o empreendedor \
            transmita sua visão de negócios de forma clara e convincente a possíveis investidores e parceiros. \
            Também serve como um mecanismo de acompanhamento e revisão, permitindo que o empreendedor ajuste sua \
            estratégia à medida que a empresa evolui e enfrenta desafios ao longo do tempo. \n\n \
            Em resumo, o plano de negócios é um documento essencial para qualquer empreendedor que deseja construir \
            uma base sólida para o sucesso de sua empresa.Ele não apenas fornece diretrizes estratégicas, mas também \
            ajuda a atrair apoio financeiro e recursos necessários para transformar uma ideia em um empreendimento \
            próspero.Portanto, dedicar tempo e esforço à elaboração de um plano de negócios bem pensado é um passo crucial \
            no caminho do empreendedorismo de sucesso.",
    },
    {
        title: "Diferentes Tipos de Prototipagem",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "A Importância do Marketing no Seu Negócio",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "Gestão do Negócio",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "Ser Um Líder",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "Exemplo",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "Outro Exemplo",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "Mais Outro Exemplo",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel euma iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    {
        title: "Mais Um Outro Exemplo",
        text:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriurer dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.\n\n \
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod \
            tincidunt ut laoreet dolore magna aliquam erat volutpat.Ut wisi enim ad minim veniam, quis \
            nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. \
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum \
            dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit \
            praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.",
    },
    // ... Repeat the same structure for other plan data items
];