import React from "react";

import Loading from "../images/materialpisopitch/ecra_abertura_sarkar_eng-12.png";

export default function LoadingScreen() {
    return (
        <div>
            <img src={Loading} className="img-100" alt="" />
        </div>
    );
}