import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import "../styles/style.css";
import axios from 'axios';

import { SERVER_URL } from "../constants";

export default function RegisterSingular({ user }) {
    const [registerError, setRegisterError] = useState(false);
    const [feedback, setFeedback] = useState('');

    const INVALID_CHARS_REGEX_GENERAL = /['"`´~^]/g; // Regular expression for special characters
    const INVALID_CHARS_REGEX_IDEA = /["´~^]/g; // Regular expression for special characters

    const validateInputGeneral = (value) => {
        return !INVALID_CHARS_REGEX_GENERAL.test(value); // Check for presence of invalid characters
    };
    const validateInputIdea = (value) => {
        return !INVALID_CHARS_REGEX_IDEA.test(value); // Check for presence of invalid characters
    };


    const setName = (e) => {
        const newValue = e.target.value;
        if (validateInputGeneral(newValue)) {
            user.name = newValue;
        } else {
            setFeedback('Name cannot contain special characters like: \' " ` ´ ~ ^');
        }
    };
    const setIdeaName = (e) => {
        const newValue = e.target.value;
        if (validateInputIdea(newValue)) {
            user.idea_name = newValue;
        } else {
            setFeedback('Idea Name cannot contain special characters like: " ` ´ ~ ^');
        }
    };
    const setEmail = (e) => {
        const newValue = e.target.value;
        if (validateInputGeneral(newValue)) {
            user.email = newValue;
        } else {
            setFeedback('Email cannot contain special characters like: \' " ` ´ ~ ^');
        }
    };

    const setPassword = (e) => {
        const newValue = e.target.value;
        if (validateInputGeneral(newValue)) {
            user.password = newValue;
        } else {
            setFeedback('Password cannot contain special characters like: \' " ` ´ ~ ^');
        }
    };

    const customCallBack = (notiMsg) => {
        setFeedback(notiMsg);
        if (!registerError) {
            setRegisterError(false);
            setTimeout(() => {
                setFeedback('');
            }, 5000); // miliseconds
            return;
        }
        setRegisterError(true);
    };

    const register = async (e) => {
        e.preventDefault();

        if (!validateInputGeneral(user.name) || !validateInputGeneral(user.email) || !validateInputGeneral(user.password) || !validateInputIdea(user.idea_name)) {
            customCallBack('Please remove special characters from your inputs.');
            return;
        }

        customCallBack('Aguarde enquanto o seu registo é efetuado...');

        axios
            .post(`${SERVER_URL}/auth/register`, user)
            .then((res) => {
                setRegisterError(false);
                customCallBack(res.data.message);
            })
            .catch((err) => {
                console.log("err: ", err);
                customCallBack(err.response.data.errors[0].msg);
                setRegisterError(true);
            });
    };

    useEffect(() => { }, [feedback]);

    return (
        <>
            <form className="text-dark-white" onSubmit={register}>
                <Row className="p-2">
                    <Col md={4} />
                    <Col md={4}>
                        <div className="mb-2">
                            <label htmlFor="registerName" className="form-label">
                                Name
                            </label>
                            <input type="text" className="form-control" id="registerName" name="name" onChange={setName} required />
                        </div>
                    </Col>
                    <Col md={4} />
                </Row>
                <Row className="p-2">
                    <Col md={4} />
                    <Col md={4}>
                        <div className="mb-2">
                            <label htmlFor="registerIdeaName" className="form-label">
                                Idea Name
                            </label>
                            <input type="text" className="form-control" id="registerIdeaName" name="ideaName" onChange={setIdeaName} required />
                        </div>
                    </Col>
                    <Col md={4} />
                </Row>
                <Row className="p-2">
                    <Col md={4} />
                    <Col md={4}>
                        <div className="mb-2">
                            <label htmlFor="registerEmail" className="form-label">
                                Email
                            </label>
                            <input type="email" className="form-control" id="registerEmail" onChange={setEmail} required />
                        </div>
                    </Col>
                    <Col md={4} />
                </Row>
                <Row className="p-2">
                    <Col md={4} />
                    <Col md={4}>
                        <div className="mb-2">
                            <label htmlFor="registerPassword" className="form-label">
                                Password
                            </label>
                            <input type="text" className="form-control" id="registerPassword" onChange={setPassword} required />
                        </div>
                    </Col>
                    <Col md={4} />
                </Row>
                <Row className="p-2">
                    <Col md={4} />
                    <Col md={4}>
                        {feedback.length > 0 ? (
                            <div className="mt-4 alert-light border-20 text-center p-1">{feedback}</div>
                        ) : (
                            <div></div>
                        )}
                    </Col>
                    <Col md={4} />
                </Row>
                <div className="mt-5 text-end">
                    <Button type="submit" className="btn bg-marine-green border-20" style={{ height: '60px' }}>
                        Criar conta
                    </Button>
                </div>
            </form>
        </>
    );
}
