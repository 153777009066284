import React, { useState } from "react";
import Sala1DentroPerto from "../../../../images/oldsarkar/sala1dentro.png";

import AvisoModal from "../../../../components/Modals/pitch/Estudio1/AvisoModal";

export default function Transition1() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="img-container">
            <img src={Sala1DentroPerto} useMap="#estudio1next-map" className="img-100" />
            <div className="pointer transition1-btn" onClick={() => setIsOpen(true)}></div>
            {isOpen && <AvisoModal setIsOpen={setIsOpen} />}
        </div >
    );
}